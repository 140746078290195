<template>
    <v-container>
        <v-row v-if="terms === false">
            <v-col cols="12">
                <h3
                class="text-center white--text"
                style="cursor: pointer"
            >terms and condition of contract</h3>
            <br />
           
                <v-card class="background-blue-tr" style="height: 81vh; overflow-x: hidden; overflow-y:scroll">
                    <v-card-text>
                        <div
                        v-for="step in steps_info_terms"
                        :key="step.step_id"
                        :step="step"
                        class="white--text"
                    >
                       <h4>{{step.step_id}} - {{step.step_name}}</h4>

                            <v-row >
                                <v-col cols="12">

                                            <p
                                                style=" text-align: justify;
margin: 0 auto;
text-justify: inter-word;"
                                            >{{ step.step_description_1 }}</p>
                                            <p
                                                style=" text-align: justify;
margin: 0 auto;
text-justify: inter-word;"
                                            >{{ step.step_description_2 }}</p>
                                            <p
                                                style=" text-align: justify;
margin: 0 auto;
text-justify: inter-word;"
v-html="step.step_description_3"
                                            ></p>
                                            <br />
                                            <br />
                                            <p
                                                class="font-weight-bold"
                                                style=" text-align: center;
margin: 0 auto;
text-justify: inter-word;"
                                            >{{ step.step_statment }}</p>
                                           
                                           
                                    
                                </v-col>
                            </v-row>
                    </div>
                    <v-btn
                                                                                                         color="white"
                                                        class="blue--text text-lowercase"
                                                        tile
                                                        dense
                                                        @click="agree"
                                                        block
                                                    >agree</v-btn>
                    </v-card-text>
                   
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" md="8">
                <v-stepper vertical v-model="e6" class="background-blue-tr fixed-height" >
                    <v-card-text>
                        <v-row no-gutters>
                        <v-col cols="12" md="2"  >
                            <h4 :step="step.step_id"  v-for="step in steps_info"
                        :key="step.step_id"
                        @click="e6 = step.step_id"
                        class="white--text ttab">
                            <span class="font-weight-bold">{{ step.step_id }} - {{ step.step_name }}</span>
                        </h4>

                        </v-col>
                        <v-col cols="12" md="10"  style="height: 90vh; overflow-x: hidden; overflow-y:scroll" >
                            <v-stepper-content  :step="step.step_id"  v-for="step in steps_info"
                        :key="step.step_id"
                        class="white--text">
                            <v-row>
                                <v-col cols="12">
                                    <v-card class="background-white-tr">
                                        <v-card-text>
                                            <p v-html="step.step_description_1"
                                                style=" text-align: justify;
                margin: 0 auto;
  text-justify: inter-word;"
                                            ></p>
                                            <br />
                                            <p
                                            v-html="step.step_description_2"
                                                style=" text-align: justify;
                margin: 0 auto;
  text-justify: inter-word;"
                                            ></p>
                                            <br />
                                            <p
                                                v-html="step.step_description_3"
                                                style=" text-align: justify;
                margin: 0 auto;
  text-justify: inter-word;"
                                            ></p>
                                            <br />
                                            <br />
                                            <p
                                                class="font-weight-bold"
                                                style=" text-align: center;
                margin: 0 auto;
  text-justify: inter-word;"
                                            >{{ step.step_statment }}</p>
                                            <br />
                                            <br />
                                            <v-row no-gutters>
                                                <v-col cols="6">
                                                    <v-btn
                                                        color="secondary"
                                                        class="blue--text text-lowercase"
                                                        tile
                                                        @click="e6 = e6 - 1"
                                                        block
                                                    >back</v-btn>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-btn
                                                        color="blue"
                                                        class="white--text text-lowercase"
                                                        tile
                                                        @click="e6 = e6 + 1"
                                                        block
                                                    >next</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                        </v-col>
                    </v-row>
                    </v-card-text>
                  
                    <div
                       
                    >
                      
                       
                    </div>
                </v-stepper>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="background-white-tr" >
                    <v-card-text class="text-center font-weight-bold">access log</v-card-text>
                    <v-tabs  background-color="transparent" color="basil" grow>
                        <v-tab class="text-lowercase">access</v-tab>
                        <v-tab-item >
                            <v-card-text style="height: 54vh; overflow-x: hidden; overflow-y:scroll">
                                <div v-for="message in access_messages" :key="message.message_id">
                               <small>- {{message.message_content}}</small> 
                            </div>
                            </v-card-text>
                            
                        </v-tab-item>
                        <v-tab class="text-lowercase" >users</v-tab>
                        <v-tab-item  style="height: 54vh; overflow-x: hidden; overflow-y:scroll">
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="6" v-for="user in users" :key="user.user_full_name">
                                        <small>- {{user.user_full_name}} ({{user.user_type}})</small> 
                                    </v-col>
                                </v-row>
                              
                               
                           
                            </v-card-text>
                        </v-tab-item>
                    </v-tabs>

                  
                </v-card>
                <v-card color="white" class="mt-2">
                    <v-card-text>
                        <small class="blue--text font-weight-bold">Note: this contract is a draft and under review & development</small>

                    </v-card-text>
                    </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import store from '@/store'
export default {
    name: 'BusinessMaterial',
    components: {

    },
    data() {
        return {
            e6: 1,
            e5: 0,
            steps_info_terms:[{
                step_name: 'This Non-Disclosure and Confidentiality Agreement',
                step_id: 0,
                step_description_1: 'The Parties are interested in exploring a potential business opportunity (the “Opportunity”).  In order to adequately evaluate whether the Parties would like to pursue the Opportunity, it is necessary for both Parties to exchange certain confidential information.',
                step_description_2: '',
                step_description_3: '',
                step_statment: ''
            },{
                step_name: 'Confidential Information',
                step_id: 1,
                step_description_1: 'The confidential information (“Confidential Information”) includes any information that is only known by the disclosing Party, and not known by the general public at the time it is disclosed, whether tangible or intangible, and through whatever means it is disclosed.',
                step_description_2: 'Confidential Information does not include information that:',
                step_description_3: 'The receiving Party lawfully gained before the disclosing Party actually disclosed it; <br><br> Is disclosed to the receiving Party by a third party who is not bound by a confidentiality agreement;<br><br> Becomes available to the general public by no fault of the receiving Party; or<br><br>Is required by law to be disclosed.',
                step_statment: ''
            },{
                step_name: 'Use of Confidential Information',
                step_id: 2,
                step_description_1: 'During the course of this Agreement, the Parties will have access to and learn of each others’ Confidential Information, including trade secrets, industry knowledge, and other confidential information. The Parties will not share any of this proprietary information at any time. The Parties also will not use any of this proprietary information for either Party’s personal/business benefit at any time. This section remains in full force and effect even after termination of the Parties’ relationship by its natural termination or early termination by either Party.',
                step_description_2: 'The receiving Party may disclose the Confidential Information to its personnel on an as-needed basis.  The personnel must be informed that the Confidential Information is confidential and the personnel must agree to be bound by the terms of this Agreement.  The receiving Party is liable for any breach of this Agreement by their personnel. ',
                step_description_3: 'In the event a Party loses Confidential Information or inadvertently discloses Confidential Information, that Party must notify the other Party within twenty-four (24) hours. That Party must also take any and all steps necessary to recover the Confidential Information and prevent further unauthorized use.<br><br>In the event a Party is required by law to disclose Confidential Information, that Party must notify the other Party of the legal requirement to disclose within three (3) business days of learning of the requirement. ',
                step_statment: 'Notices must be made in accordance with Section 8 of this Agreement.'
            },{
                step_name: 'Ownership and Title',
                step_id: 3,
                step_description_1: 'Nothing in this Agreement will convey a right, title, interest, or license in the Confidential Information to the receiving party.  The Confidential Information will remain the exclusive property of the disclosing party.',
                step_description_2: ' ',
                step_description_3: '',
                step_statment: ''
            },{
                step_name: 'Return of Confidential Information',
                step_id: 4,
                step_description_1: 'Upon termination of this Agreement, the receiving party must return all tangible materials it has that contain the Confidential Information it received, including all electronic and hard copies.  This includes, but is not limited to, any notes, memos, drawings, summaries, excerpts and anything else derived from the Confidential Information.',
                step_description_2: ' ',
                step_description_3: '',
                step_statment: ''
            },{
                step_name: 'Remedies',
                step_id: 5,
                step_description_1: 'The Parties agree the Confidential Information is unique in nature and money damages will not adequately remedy the irreparable injury breach of this Agreement may cause the injured Party.  The injured Party is entitled to seek injunctive relief, as well as any other remedies that are available in law and equity.',
                step_description_2: ' ',
                step_description_3: '',
                step_statment: ''
            },{
                step_name: 'Relationship of the Parties. ',
                step_id: 6,
                step_description_1: 'No Binding Agreement to Pursue Opportunity.',
                step_description_2: ' The Parties agree they are exploring a potential Opportunity and sharing their Confidential Information is not a legal obligation to pursue the Opportunity.  Either Party is free to terminate discussions or negotiations related to the Opportunity at any time.',
                step_description_3: '<strong>No Exclusivity</strong><br>The Parties understand this Agreement is not an exclusive arrangement.  The Parties agree they are free to enter into other similar agreements with other parties.<br><br><strong>Independent Contractors</strong> <br> The Parties to this Agreement are independent contractors.  Neither Party is an agent, representative, partner, or employee of the other Party. ',
                step_statment: ''
            },{
                step_name: 'General',
                step_id: 7,
                step_description_1: 'Assignment. The Parties may not assign their rights and/or obligations under this Agreement.',
                step_description_2: ' Complete Contract. This Agreement constitutes the Parties entire understanding of their rights and obligations. This Agreement supersedes any other written or verbal communications between the Parties.  Any subsequent changes to this Agreement must be made in writing and signed by both Parties. ',
                step_description_3: '',
                step_statment: ''
            },{
                step_name: 'Notice',
                step_id: 8,
                step_description_1: 'Notices. All notices under this Agreement must be sent by email with return receipt requested or certified or registered mail with return receipt requested.',
                step_description_2: '',
                step_description_3: '',
                step_statment: ''
            }],
        }
    },
    async mounted() {
        await store.dispatch('reports/getUsers', {})
        await store.dispatch('reports/getMessages', {})
    },
    computed: {

        steps_info() {
            return store.state.reports.steps_info.steps_content
        },
        users() {
            return store.state.reports.users
        },
        access_messages() {
            return store.state.reports.access_messages
        },
        terms() {
            return store.state.user_persist.terms
        }
    },
    methods: {
        agree(){
            store.dispatch('user_persist/agree')
        }
    }
}
</script>
<style>
.fixed-height{
    height: 90vh; 
    overflow-x: hidden; 
    overflow-y:hidden
}

.ttab:hover{
    color: #0b102d!important;
}
@media only screen and (max-width: 450px) {
    .fixed-height{
    height: auto; 
    overflow-x: hidden; 
    overflow-y:hidden
}

}
</style>